import { CaretDownOutlined } from '@ant-design/icons'
import { Avatar, Flex, Form, Select } from 'antd'
import React from 'react'

export const SelectTeam = ({ options, placeholder, formName, formLabel }) => {
  return (
    <Form.Item name={formName} label={formLabel}>
      <Select
        options={options}
        placeholder={placeholder ?? 'הזינו שם קבוצה'}
        showSearch
        allowClear
        optionFilterProp="label"
        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none', color: 'black' }} />}
        optionRender={(option) => (
          <Flex justify="space-between" align="center">
            {option.data.label}
            <Avatar size={43} shape="square" src={option.data.iconUrl} />
          </Flex>
        )}
        labelRender={(label) => {
          const selectedLabel = options.filter((option) => option.value === label.value)
          return (
            <Flex justify="space-between" align="center">
              {selectedLabel[0].label}
              <Avatar size={43} shape="square" src={selectedLabel[0].iconUrl} />
            </Flex>
          )
        }}
      />
    </Form.Item>
  )
}
