import { Col, Flex, Row } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { ArticlesWrapper } from '../../components/articles/ArticlesWrapper'

export const exclusiveContentFolderId = '11537'

export default function ExclusiveContent() {
  return (
    <Row justify="center" className="bg bg4">
      <HelmetHeader />
      <Col flex="1300px">
        <Flex vertical={true} gap={10} style={{ marginTop: 10 }}>
          <ArticlesWrapper
            pageKey="Exclusive"
            filter={[exclusiveContentFolderId]}
            initialBlogsData={{ pageSize: 12, filter: [exclusiveContentFolderId], pageKey: 'exclusiveContent' }}
            titleContent={'תוכן אקסקלוסיבי'}
            useExclusive
          />
        </Flex>
      </Col>
    </Row>
  )
}
