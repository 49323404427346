import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { exclusiveContentFolderId } from '../../pages/exclusive-content/ExclusiveContent'

const createBlogData = (blog) => {
  return {
    ...blog,
    publishDate: dayjs(blog.publishDate, 'x').format('DD.MM.YY'),
    imageUrl: blog.image,
    description: blog.subTitle,
    authorName: blog.author,
    url: blog.url,
  }
}

export const createMyTeamsArticles = (flatBlogs, teamId, memoizedTeamData) => {
  const { relatedFolderIds, title, tags } = memoizedTeamData[teamId]
  return flatBlogs
    .filter(
      (blog) =>
        relatedFolderIds.includes(blog.categoryId) &&
        (blog.title.includes(title) || tags.some((tag) => blog.tags.includes(tag))),
    )
    .map((blog) => ({
      ...createBlogData(blog),
      teamName: title,
    }))
}

export const createHomePageArticles = (flatBlogs, teamsData, memoizedTeamData) => {
  let homePageBlogs = []
  if (!isEmpty(teamsData) && !isEmpty(memoizedTeamData)) {
    homePageBlogs = flatBlogs.map((blog) => {
      if (blog.categoryId === exclusiveContentFolderId) {
        return {
          ...createBlogData(blog),
          exclusiveContent: 'רק באזור האישי',
        }
      }

      const relatedTeam = teamsData.filter((team) => {
        const { relatedFolderIds, title, tags } = memoizedTeamData[team._id]
        return (
          relatedFolderIds.includes(blog.categoryId) &&
          (blog.title.includes(title) || tags.some((tag) => blog.tags.includes(tag)))
        )
      })

      return {
        ...createBlogData(blog),
        teamName: relatedTeam[0]?.name,
      }
    })
  } else {
    homePageBlogs = flatBlogs.map((blog) => {
      return {
        ...createBlogData(blog),
        exclusiveContent: 'רק באזור האישי',
      }
    })
  }
  return homePageBlogs
}

export const formatArticlesByPage = (pageKey, flatBlogs, teamsData, memoizedTeamData, teamId, filter) => {
  // My Teams Page
  if (pageKey === 'MyTeams') {
    if (!isEmpty(teamId) && !isEmpty(memoizedTeamData)) {
      return createMyTeamsArticles(flatBlogs, teamId, memoizedTeamData)
    }
  }

  // Exclusive Content Page
  if (pageKey === 'Exclusive') {
    if (!isEmpty(filter)) {
      return (
        flatBlogs.map((blog) => ({
          ...createBlogData(blog),
          exclusiveContent: 'רק באזור האישי',
        })) || []
      )
    }
  }

  // Home Page
  if (pageKey === 'Home') {
    return createHomePageArticles(flatBlogs, teamsData, memoizedTeamData)
  }
  return []
}
