import Articles from './Articles'
import { useBlog } from '../../hooks/useBlog'
import { useMemo } from 'react'
import { formatArticlesByPage } from './ArticlesWrapper.utils'

export const ArticlesWrapper = ({
  pageKey = 'Home',
  initialBlogsData,
  teamsData,
  teamId,
  filter = ['11537'],
  titleContent,
  useExclusive,
}) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useBlog(initialBlogsData)

  const memoizedTeamData = useMemo(() => {
    return teamsData?.reduce((acc, team) => {
      const categories = []
      team.sportCategories.forEach((category) => {
        return categories.push(...category.folderIds)
      })
      acc[team._id] = {
        relatedFolderIds: categories,
        title: team.name,
        tags: team.tags,
      }
      return acc
    }, {})
  }, [teamsData])

  const formattedArticles = useMemo(() => {
    if (data?.pages) {
      const flatBlogs = data.pages.flatMap((page) => page.blogs)
      return formatArticlesByPage(pageKey, flatBlogs, teamsData, memoizedTeamData, teamId, filter)
    }
    return []
  }, [data?.pages, filter, memoizedTeamData, pageKey, teamId, teamsData])

  return (
    <Articles
      articles={formattedArticles}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      pageSize={initialBlogsData.pageSize}
      teamId={teamId}
      titleContent={titleContent}
      useExclusive={useExclusive}
    />
  )
}
