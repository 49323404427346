import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Flex, Row, notification, Typography } from 'antd'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import HeaderBenefit from '../../components/elements/HeaderBenefit'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { FavoriteTeamPopup } from './components/FavoriteTeamPopup'
import { useUser } from '../../hooks/useUser'
import { ArticlesWrapper } from '../../components/articles/ArticlesWrapper'
import { useUserPreferredTeams } from '../../hooks/useUserPreferredTeams'
import { exclusiveContentFolderId } from '../exclusive-content/ExclusiveContent'
import Loader from '../../components/layouts/Loader'

const benefitUrl =
  'https://my.sport5.co.il/newsletter/register?utm_source=sport5&utm_medium=owned&utm_content=red_header_personal_area&utm_campaign=newsletter'

export default function Home() {
  useUserNewsletter()
  const location = useLocation()
  const { user, updateProfile } = useUser()
  const [isTeamPopupVisible, setIsTeamPopupVisible] = useState(false)
  const { data: teamsData, isLoading } = useUserPreferredTeams()

  useEffect(() => {
    if (user && !user.disablePopup && location.state?.showPopup) {
      window.history.replaceState({}, '')
      const closeCount = localStorage.getItem('favoriteTeamPopupCloseCount') || 0
      if (closeCount < 3) {
        setTimeout(() => {
          setIsTeamPopupVisible(true)
        }, 5 * 1000)
      }
    }
  }, [location.state?.showPopup, user])

  useEffect(() => {
    if (location.state?.isWelcomePopup) {
      window.history.replaceState({}, '')
      notification.open({
        icon: false,
        description: (
          <Flex vertical justify="center" align="center" gap={12}>
            <img src={'/icons/ok.svg'} alt="success" width={44} />
            <Typography.Title level={1} style={{ margin: 0 }}>
              {'קיבלנו!'}
            </Typography.Title>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {'ברוך הבא לאזור האישי :)'}
            </Typography.Title>
          </Flex>
        ),
      })
    }
  }, [location])

  const handlePopupClose = async () => {
    let closeCount = localStorage.getItem('favoriteTeamPopupCloseCount') || 0
    closeCount = parseInt(closeCount, 10) + 1
    localStorage.setItem('favoriteTeamPopupCloseCount', closeCount)
    setIsTeamPopupVisible(false)
    if (closeCount >= 3) {
      await updateProfile({ disablePopup: true })
    }
  }

  if (isLoading) return <Loader />

  return (
    <Row justify="center" className="bg bg4">
      <FavoriteTeamPopup
        open={isTeamPopupVisible}
        updateProfile={updateProfile}
        setOpen={setIsTeamPopupVisible}
        handleClose={handlePopupClose}
      />
      <HelmetHeader />
      <Col flex="740px">
        <Flex vertical={true} gap={10}>
          <HeaderBenefit benefitImageSrc={'/images/newsletter-cta.png'} benefitUrl={benefitUrl} />
          <ArticlesWrapper
            pageKey="Home"
            initialBlogsData={{
              pageSize: 12,
              sort: { publishDate: -1 },
              filter: [exclusiveContentFolderId],
              teamIds: teamsData.map((team) => team._id),
            }}
            teamsData={teamsData}
          />
        </Flex>
      </Col>
    </Row>
  )
}
